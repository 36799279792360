<template>
  <div class="page notice-list-page" :class="{ 'more-depth': $route.meta && $route.meta.headerType === 'more-depth' }">
    <div class="container">
      <div class="page-header">
        <div class="title">{{ $__t('공지사항') }}</div>
        <div class="secondary">{{ $__t('럭스테이의 새로운 소식을 확인하세요.') }}</div>
      </div>

      <div class="page-body">
        <notice-list />
      </div>
    </div>
  </div>
</template>

<script>
import NoticeList from '@/components/resources/notice/NoticeList.vue';

export default {
  components: { 
    NoticeList 
  },
  
};
</script>

<style lang="scss" scoped>
.page.notice-list-page {

  .page-header {
    display: block;
    padding: 2 * $unit;
    border-bottom: 1px solid $color-gray-3;
    z-index: 1;
    display: block;

    .title {
      font-size: unit(22);
      font-weight: 500;
    }

    .secondary {
      font-size: unit(14);
      line-height: unit(20);
      color: #878d91;
    }
  }

  .page-body {
    padding: 0;
  }
}
</style>